<template>
    <div class="perform_box">
        <div class="title m_b flex_center_between_box">
            <div class="title_txt flex_center_start_box">
                <img src="../../../assets/img/detail/title_ic6.png" />
                Export
            </div>
        </div>

        <div class="footer" v-if="transactionsTable.length > 0">
            <div class="ecahrts-header">
                <span class="title-left title_txt">
                    <img src="../../../assets/img/detail/title_ic6.png" alt="" style="margin-right: 8px" />
                    Export
                </span>

                <div class="head_loging">
                    <el-switch active-color="#13ce66" v-model="loginStatus"></el-switch>
                </div>
            </div>

            <div v-show="loginStatus" class="ecahrtstu relatedport computer_right_cont_box">
                <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" :header="title" name="export.xls" v-show="tabActiveName === '1'">
                    <div class="btn_export flex_center" @click="exportCompany">
                        <img src="../../../assets/img/search/export_ic1.png" />
                        Export
                    </div>
                </download-excel>
            </div>
            <div class="import_export_box" v-show="loginStatus">
                <div class="top_tab" v-if="transactionsTable.length > 0">
                    <el-tabs v-model="tabActiveName" v-loading="loading" type="card" class="tab_box" @tab-click="handleTabClick">
                        <el-tab-pane label="Recent Transaciton" name="1" class="result_tab">
                            <div class="table_box">
                                <el-table ref="treeTable" :data="transactionsTable" border style="width: 100%" class="no_page computer_right_cont_box">
                                    <!-- 空数据状态的插槽 -->
                                    <template slot="empty">No data</template>
                                    <el-table-column label="Date" prop="date" width="100" align="left" show-overflow-tooltip :resizable="false" />
                                    <el-table-column label="HS Code" prop="hsCode" width="90" align="left" show-overflow-tooltip :resizable="false" />
                                    <el-table-column label="Products" prop="products" align="left" show-overflow-tooltip :resizable="false" class-name="product"></el-table-column>
                                    <el-table-column label="Major Buyers" prop="buyerEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link" width="230">
                                        <template slot-scope="scope">
                                            <div @click="seiad1(scope.row)" class="line_1" style="width: 200px">{{ scope.row.buyerEn }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Destination Country" width="160" prop="exportCountryEn" align="left" sortable :sort-method="sortName" show-overflow-tooltip :resizable="false" class-name="company_link">
                                        <template slot-scope="scope">
                                            <div style="width: 140px" @click="gotocountry2(scope.row)" class="line_1">{{ scope.row.exportCountryEn }}</div>
                                            <!-- <span @click="gotocountry2(scope.row)">{{scope.row.exportCountryEn  }}</span> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Amount(USD M)" width="140" prop="amount" align="left" sortable :sort-method="sortScore" show-overflow-tooltip :resizable="false">
                                        <template slot-scope="scope">
                                            {{ scope.row.amount | FilterNum }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <!-- 手机端 -->
                                <div class="phone_page_bg">
                                    <div class="table_box">
                                        <ul class="phone_table_box">
                                            <li class="phone_table_box_item" v-for="(item, index) in transactionsTable" :key="index">
                                                <div class="phone_table_box_item_right">
                                                    <h1 class="line_1" @click="seiad1(item)">Major Buyers:{{ item.buyerEn }}</h1>
                                                    <h2 class="line_1">Date: {{ item.date }}</h2>
                                                    <h2 class="line_1">
                                                        <span>HSCode:{{ item.hsCode }}</span>
                                                    </h2>
                                                    <h2 class="line_1">
                                                        <span>Product:{{ item.products }}</span>
                                                    </h2>

                                                    <h2 class="line_1">
                                                        <span>Destination Country:{{ item.exportCountryEn }}</span>
                                                    </h2>
                                                    <h2 class="line_1">
                                                        <span>Amount(USD M):{{ item.amount | FilterNum }}</span>
                                                    </h2>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="page_i_box">
                                    <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                    <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Trend" name="2" class="result_tab">
                            <div ref="trendEchart" class="ehcarswide"></div>
                        </el-tab-pane>
                        <el-tab-pane label="Buyers" name="3" class="result_tab">
                            <div class="table_box">
                                <el-table ref="buyersTable" :data="buyersTable" border style="width: 100%" class="no_page computer_right_cont_box">
                                    <!-- 空数据状态的插槽 -->
                                    <template slot="empty">No data</template>
                                    <el-table-column label="Country" prop="countryEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                        <template slot-scope="scope">
                                            <span @click="gotocountry1(scope.row)">{{ scope.row.countryEn }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Company name" align="left" show-overflow-tooltip :resizable="false" class-name="company_link" width="450">
                                        <template slot-scope="scope">
                                            <div class="line_1" style="width: 430px" @click="setid(scope.row)">{{ scope.row.nameEn }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Transaction" prop="tansaction" align="left" show-overflow-tooltip :resizable="false">
                                        <template slot-scope="scope">
                                            <div>{{ scope.row.tansaction | FilterNum }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Percentage(%)" prop="percentage" align="left" show-overflow-tooltip :resizable="false" />
                                </el-table>
                                <!-- 手机端 -->
                                <div class="phone_page_bg">
                                    <div class="table_box">
                                        <ul class="phone_table_box">
                                            <li class="phone_table_box_item" v-for="(item, index) in buyersTable" :key="index">
                                                <div class="phone_table_box_item_right">
                                                    <h1 class="line_1" @click="setid(item)">{{ item.nameEn }}</h1>

                                                    <h2 class="line_1">
                                                        <span @click="gotocountry1(item)">Country:{{ item.countryEn }}</span>
                                                    </h2>

                                                    <h2 class="line_1">
                                                        <span>Transactions:{{ item.tansaction | FilterNum }}</span>
                                                    </h2>
                                                    <h2 class="line_1">
                                                        <span>Percentage(%):{{ item.percentage }}</span>
                                                    </h2>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Countries" name="4" class="result_tab">
                            <div class="table_box">
                                <el-table ref="countriesTable" :data="countriesTable" border style="width: 100%" class="no_page computer_right_cont_box">
                                    <!-- 空数据状态的插槽 -->
                                    <template slot="empty">No data</template>
                                    <el-table-column label="Country" prop="countryEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                        <template slot-scope="scope">
                                            <span @click="gotocountry1(scope.row)">{{ scope.row.countryEn }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Transactions" prop="transaction" align="left" show-overflow-tooltip :resizable="false">
                                        <template slot-scope="scope">
                                            <div>{{ scope.row.transaction | FilterNum }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Percentage(%)" prop="percentage" align="left" show-overflow-tooltip :resizable="false" />
                                </el-table>
                                <!-- 手机端 -->
                                <div class="phone_page_bg">
                                    <div class="table_box">
                                        <ul class="phone_table_box">
                                            <li class="phone_table_box_item" v-for="(item, index) in countriesTable" :key="index">
                                                <div class="phone_table_box_item_right">
                                                    <h2 class="line_1">
                                                        <span @click="gotocountry1(item)">Country:{{ item.countryEn }}</span>
                                                    </h2>
                                                    <h2 class="line_1">
                                                        <span>Transactions:{{ item.tansaction | FilterNum }}</span>
                                                    </h2>
                                                    <h2 class="line_1">
                                                        <span>Percentage(%):{{ item.percentage }}</span>
                                                    </h2>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <div class="computer_right_cont_box" v-if="parentTablelist.length > 0">
            <div class="title" style="margin-top: 20px; margin-bottom: 0">
                <span class="title-left title_txt">
                    <img src="../../../assets/img/detail/title_ic6.png" style="margin-right: 8px" />
                    Related Export
                </span>
            </div>
            <div class="infinite-list-wrapper" v-infinite-scroll="infiniteScroll" style="overflow: auto" :infinite-scroll-disabled="false" :infinite-scroll-distance="5">
                <!-- <div v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
                <div class="footer" v-for="(item, index) in parentTablelist" :key="index" v-loading="item.loading2">
                    <div class="ecahrts-header">
                        <span class="title-left title_txt">
                            <img src="../../../assets/img/detail/title_ic6.png" alt="" style="margin-right: 8px" />
                            <span v-show="!showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid1(item)">{{ item.nameEn }}</span>
                            <span v-show="showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid(item)">{{ item.nameCn }}</span>
                            Export
                        </span>
                        <span style="display: flex; justify-content: flex-start; width: 280px">relations:{{ item.typeEn }}</span>

                        <div class="head_loging">
                            <el-switch active-color="#13ce66" :value="loginStatus1[index]" @change="loginPop(index, item.aaaid, item.nameEn)"></el-switch>
                        </div>
                    </div>
                    <div class="relatedport" v-show="tabActiveName === '1' && loginStatus1[index]">
                        <download-excel class="export-excel-wrapper" :data="item.reladeexport" :fields="json_fields" :header="title" name="export.xls">
                            <div class="btn_export flex_center" @click="exportCompany1(index)">
                                <img src="../../../assets/img/search/export_ic1.png" />
                                Export
                            </div>
                        </download-excel>
                    </div>
                    <div v-show="loginStatus1[index]" class="ecahrtstu">
                        <div class="table">
                            <div class="table_box" style="position: relative">
                                <div class="import_export_box">
                                    <div class="top_tab">
                                        <el-tabs v-model="tabActiveName" v-loading="loading" type="card" class="tab_box" @tab-click="handleTabClick">
                                            <el-tab-pane label="Recent Transaciton" name="1" class="result_tab">
                                                <div class="table_box">
                                                    <el-table ref="treeTable" :data="item.transactionsTable" border style="width: 100%" class="no_page">
                                                        <!-- 空数据状态的插槽 -->
                                                        <template slot="empty">No data</template>
                                                        <el-table-column label="Date" prop="date" width="100" align="left" show-overflow-tooltip :resizable="false" />
                                                        <el-table-column label="HS Code" prop="hsCode" width="90" align="left" show-overflow-tooltip :resizable="false" />
                                                        <el-table-column label="Products" prop="products" align="left" show-overflow-tooltip :resizable="false" class-name="product"></el-table-column>
                                                        <el-table-column label="Major Buyers" prop="buyerEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link" width="230">
                                                            <template slot-scope="scope">
                                                                <div @click="seiad1(scope.row)" class="line_1" style="width: 200px">{{ scope.row.buyerEn }}</div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Destination Country" width="160" prop="exportCountryEn" sortable :sort-method="sortName" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                                            <template slot-scope="scope">
                                                                <div class="line_1" style="width: 120px" @click="gotocountry2(scope.row)">{{ scope.row.exportCountryEn }}</div>
                                                                <!-- <span @click="gotocountry2(scope.row)">{{scope.row.exportCountryEn  }}</span> -->
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Amount(USD M)" width="160" align="left" sortable :sort-method="sortScore" :resizable="false">
                                                            <template slot-scope="scope">
                                                                <div style="width: 140px" class="line_1">{{ scope.row.amount | FilterNum }}</div>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                    <div class="page_i_box">
                                                        <Page :totalFont="true" :total="item?.page?.total" @onPageChange="onPageChange1(index, item.aaaid, item.nameEn, $event)" @currtentPageChange="currtentPageChange1(index, item.aaaid, item.nameEn, $event)"></Page>
                                                        <div class="search_rsult_txt line_2">{{ item?.page?.pageNo }}-{{ item?.page?.pageSize }} of over {{ item?.page?.total | FilterNum }} results</div>
                                                    </div>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane label="Trend" name="2" class="result_tab">
                                                <div class="echarts" ref="chartRef" style="width: 1150px; height: 568px"></div>
                                            </el-tab-pane>
                                            <el-tab-pane label="Buyers" name="3" class="result_tab">
                                                <div class="table_box">
                                                    <el-table ref="buyersTable" :data="item.buyersTable" border style="width: 100%" class="no_page">
                                                        <!-- 空数据状态的插槽 -->
                                                        <template slot="empty">No data</template>
                                                        <el-table-column label="Country" prop="countryEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                                            <template slot-scope="scope">
                                                                <span @click="gotocountry1(scope.row)">{{ scope.row.countryEn }}</span>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Company name" align="left" show-overflow-tooltip :resizable="false" class-name="company_link" width="450">
                                                            <template slot-scope="scope">
                                                                <div class="line_1" style="width: 430px" @click="setid(scope.row)">{{ scope.row.nameEn }}</div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Transaction" prop="tansaction" align="left" show-overflow-tooltip :resizable="false">
                                                            <template slot-scope="scope">
                                                                <div>{{ scope.row.tansaction | FilterNum }}</div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Percentage(%)" prop="percentage" align="left" show-overflow-tooltip :resizable="false" />
                                                    </el-table>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane label="Countries" name="4" class="result_tab">
                                                <div class="table_box">
                                                    <el-table ref="countriesTable" :data="item.countriesTable" border style="width: 100%" class="no_page">
                                                        <!-- 空数据状态的插槽 -->
                                                        <template slot="empty">No data</template>
                                                        <el-table-column label="Country" prop="countryEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                                            <template slot-scope="scope">
                                                                <span @click="gotocountry1(scope.row)">{{ scope.row.countryEn }}</span>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Transactions" prop="transaction" align="left" show-overflow-tooltip :resizable="false">
                                                            <template slot-scope="scope">
                                                                <div>{{ scope.row.transaction | FilterNum }}</div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Percentage(%)" prop="percentage" align="left" show-overflow-tooltip :resizable="false" />
                                                    </el-table>
                                                </div>
                                            </el-tab-pane>
                                        </el-tabs>
                                    </div>
                                </div>
                                <!-- <button @click="update()" class="btn_blue">High quality information</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="back-to-top" v-show="showBackToTop" @click="scrollToTop">
            <button class="el-icon-top gaodu"></button>
        </div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <div class="detail_cont">
                    <div class="detail_cont">
                        <div class="detail_cont_text">• Import & export information is a paid feature, this will cost 2 units.</div>
                    </div>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" id="ok" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getImportExportTransaction, getImportOrExport, getrelatedCompanylist, getCompanyDetailMenuList, ordersAdd } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';

import Page from '@/components/page';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    components: { Page },
    data() {
        return {
            showBackToTop: false,
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            tabActiveName: '1',
            // searchName:'',
            transactionsTable: [],
            loginStatus: true,
            loading: true,
            trendYList: [],
            trendXList: [],
            buyersTable: [],
            countriesTable: [],
            // 下载
            title: 'Export',
            json_fields: {
                Date: 'date',
                // Type: 'type',
                HSCode: 'hsCode',
                Product: 'products',
                'Major Suppliers': 'suppliers',
                'Origin Country': 'country',
                'Amount(USD M)': 'amount',
            },
            DetailsForm: [],
            type: [2],
            loginStatus1: [],

            parentTablelist: [],
            newDialog: false,
            indexclose: '',
            Obj: {
                id: '',
                name: '',
                index: '',
            },
            params: {},
            params1: {},
            window:window
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.curPagePower1);
            },
            set() {},
        },
    },
    watch: {},
    created() {
        this.getTransaction();
        this.getData();
        this.getrelateList();
    },
    mounted() {
        // window.addEventListener('resize', this.riskEchart)

        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // 移除页面滚动事件的监听
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        closeDialog() {
            const okButton = document.getElementById('ok');

            okButton.removeEventListener('click', this.onSubmitkoudian);
            this.loginStatus1[this.indexclose] = false;
            this.parentTablelist[this.indexclose].loading2 = false;
            this.$set(this.parentTablelist, this.indexclose, this.parentTablelist[this.indexclose]);
            this.newDialog = false;
        },
        // 提示扣点
        onSubmitkoudian() {
            let that = this;
            let id = this.Obj.id;
            let nameEn = this.Obj.name;

            this.getOrdersAdd(13, id, nameEn).then(res => {
                if (res) {
                    let params = this.params;
                    let params1 = this.params1;
                    let index = that.Obj.index;
                    this.getrelatedinport(params, index, params1);
                } else {
                    let index = that.Obj.index;
                    this.loginStatus1[index] = false;
                    this.parentTablelist[index].loading2 = false;
                    this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                }
            });
            this.newDialog = false;
        },
        // 取消
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
        sortName(a, b) {
            return a.exportCountryEn.localeCompare(b.exportCountryEn);
        },
        // 排序
        sortScore(a, b) {
            return a.amount - b.amount;
        },
        handleScroll() {
            // 判断页面滚动距离是否超过200px，更新showBackToTop的值
            this.showBackToTop = window.pageYOffset > 1000;
        },
        scrollToTop() {
            // 使用scrollTo()方法将页面滚动到顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        getData() {
            // this.searchName = this.$route.query.searchname ? this.$route.query.searchname : ''
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            const base = JSON.parse(sessionStorage.getItem('base'));
            let params = 'aaaId=' + id3a + '&countryCode=' + companyCountry + '&type=' + this.type + '&companyName=';
            let that = this;
            // let params = 'aaaId=156107587326&countryCode=CN&type=1'
            setTimeout(function () {
                getImportOrExport(params).then(result => {
                    let data = JSON.parse(crypto.decrypt(result));
                    if (data && data.code) {
                        that.loading = false;
                        let obj = data.data;
                        // this.transactionsTable = obj.transactions
                        that.buyersTable = obj.buyers;
                        that.countriesTable = obj.countries;

                        const list = obj.trends;
                        if (list) {
                            list.forEach((item, index) => {
                                that.trendYList.push(item.translation);
                                that.trendXList.push(item.year);
                            });
                        }
                    } else {
                        that.countriesTable = [];
                        that.buyersTable = [];
                        that.loading = false;
                    }
                });
            }, 1000);
        },
        getTransaction() {
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            const base = JSON.parse(sessionStorage.getItem('base'));
            let params = 'aaaId=' + id3a + '&countryCode=' + companyCountry + '&type=' + this.type + '&page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&companyName=';
            getImportExportTransaction(params).then(result => {
                let data = JSON.parse(crypto.decrypt(result));
                // console.log('result- getTransactionExport', data);
                if (data && data.code && data.data) {
                    this.loading = false;
                    this.page.total = Number(data.data.total);
                    this.transactionsTable = data.data.rows;
                } else {
                    this.loading = false;
                    this.transactionsTable = [];
                }
            });
        },
        // 国家跳转
        gotocountry1(row) {
            if (row.countryCode != 'n.a.') {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + row.countryCode,
                });

                window.open(routeData.href, '_blank');
            }
            //  if(!row.countryCode.includes(';')){

            //  }
        },
        gotocountry2(row) {
            if (row.exportCountryEn != 'n.a.') {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + row.exportCountrycode,
                });

                window.open(routeData.href, '_blank');
            }
            //  if(!row.countryCode.includes(';')){

            //  }
        },
        setid(row) {
            // sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaaid + '&companyCountry=' + row.countryCode,
            });
            window.open(routeData.href, '_blank');
        },
        seiad1(row) {
            // sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.buyerAaaid + '&companyCountry=' + row.exportCountryEn,
            });
            window.open(routeData.href, '_blank');
        },
        //导出
        exportCompany() {
            if (this.curPagePower[0].power) {
                this.DetailsForm = this.transactionsTable;
            } else {
                this.message();
                // this.$parent.getOrdersAdd(13).then(res => {
                //     if (res) this.DetailsForm = this.transactionsTable
                // });
            }
        },
        // 关联公司枫叶
        onPageChange1(index, id, nameEn, pageNo) {
            let params = 'aaaId=' + id + '&companyName=' + '&type=' + this.type + '&page=' + pageNo + '&pageSize=' + this.parentTablelist[index].page.pageSize;
            getImportExportTransaction(params).then(result => {
                let data = JSON.parse(crypto.decrypt(result));
                if (data && data.code && data.data) {
                    this.parentTablelist[index].loading2 = false;
                    this.parentTablelist[index].page.pageNo = pageNo;
                    this.parentTablelist[index].transactionsTable = data.data.rows;
                    this.parentTablelist[index].page.total = Number(data.data.total);
                    this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                } else {
                    this.parentTablelist[index].loading2 = false;
                    this.parentTablelist[index].transactionsTable = [];
                }
            });
        },
        currtentPageChange1(index, id, nameEn, pageSize) {
            // this.page.pageSize = pageSize;

            // this.loginPop(index, id, nameEn)
            let params = 'aaaId=' + id + '&companyName=' + '&type=' + this.type + '&page=' + this.parentTablelist[index].page.pageNo + '&pageSize=' + pageSize;
            getImportExportTransaction(params).then(result => {
                let data = JSON.parse(crypto.decrypt(result));

                if (data && data.code && data.data) {
                    this.parentTablelist[index].loading2 = false;
                    this.parentTablelist[index].page.pageSize = pageSize;
                    this.parentTablelist[index].transactionsTable = data.data.rows;
                    this.parentTablelist[index].page.total = Number(data.data.total);
                    this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                } else {
                    this.parentTablelist[index].loading2 = false;
                    this.parentTablelist[index].transactionsTable = [];
                }
            });
            // this.getTransaction();    this.loginPop(index, item.aaaid, item.nameEn)
        },
        exportCompany1(index) {
            // if (this.curPagePower[0].power) {
            this.parentTablelist[index].reladeexport = this.parentTablelist[index].transactionsTable;
            this.$set(this.parentTablelist, index, this.parentTablelist[index]);
            // } else {
            //     this.message();
            //     // this.$parent.getOrdersAdd(13).then(res => {
            //     //     if (res) this.DetailsForm = this.transactionsTable
            //     // });
            // }
        },
        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.getTransaction();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.getTransaction();
        },
        //切换 tab
        handleTabClick() {
            if (this.tabActiveName == '2') {
      
                  if(this.window.innerWidth<821){
                    this.trendXList=this.trendXList.slice(-15)
                      this.trendYList=this.trendYList.slice(-15)
                  }
                this.tabMarketEcharts(this.$refs.trendEchart, 'No. fo Buyer', this.trendXList, this.trendYList);
            }
        },

        tabMarketEcharts(obj, titleName, xList, yList) {
            this.echartsBox = this.$echarts.init(obj);
            let that = this;
            const option = {
                tooltip: {
                    trigger: 'item',
                    borderWidth: 1,
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    shadowColor: 'rgba(0, 0, 0, 0.2)',
                    shadowOffsetY: 2,
                    shadowBlur: 4,
                    backgroundColor: '#fff',
                    textStyle: {
                        color: '#022955',
                        fontSize: 14,
                    },
                    formatter: function (params) {
                        params['value'] = that.filter(params['value']);
                        return params['seriesName'] + '<br/>' + params['name'] + '<br/>' + params['value'];
                    },
                },
                grid: {
                    left: '6%',
                    right: '60',
                    bottom: '60',
                },
                xAxis: {
                    data: xList,
                    axisLabel: {
                        textStyle: {
                            color: '#8497AB',
                            fontSize: 10,
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#8497AB',
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#8497AB',
                            fontSize: 10,
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#8497AB',
                        },
                    },
                    axisTick: {
                        show: true,
                        length: 4,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: titleName,
                        type: 'bar',
                        barWidth:that.window.innerWidth>821?'20':'10',
                        itemStyle: { color: '#8497AB' },
                        emphasis: {
                            itemStyle: {
                                color: '#FF7600',
                            },
                        },
                        data: yList,
                    },
                ],
            };
            this.echartsBox.setOption(option);
        },

        infiniteScroll() {
            this.routeLoad = false;
            // this.page.pageNo += 1; // 页码每次滚动+1
            // this.getrelateList();
        },
        getrelateList() {
            getrelatedCompanylist({ id3a: this.$route.query.id3a, page: this.page.pageNo, pageSize: this.page.pageSize, resourceType: 4 }).then(res => {
                if (res) {
                    let list = res.data.rows;
                    res.data.rows == null ? (res.data.rows = []) : res.data.rows;
                    this.parentTablelist = res.data.rows;
                    this.parentTablelist.map(item => {
                        item.loading2 = false;
                        item.transactionsTable = [];
                        item.buyersTable = [];
                        item.countriesTable = [];
                        // ehcarts图处理
                        item.chartInstance = null;
                        item.trendYList = [];
                        item.trendXList = [];
                        item.reladeexport = [];
                        item.page = {
                            pageNo: 1,
                            pageSize: 10,
                            total: 0,
                        };
                        // item.loading1=fal
                    });
                }
            });
        },
        async loginPop(index, id, nameEn) {
            this.indexclose = index;
            this.$set(this.loginStatus1, index, !this.loginStatus1[index]);

            if (this.loginStatus1[index]) {
                this.parentTablelist[index].loading2 = true;

                let params = 'aaaId=' + id + '&companyName=' + '&type=' + this.type + '&page=' + this.parentTablelist[index].page.pageNo + '&pageSize=' + this.parentTablelist[index].page.pageSize;
                let params1 = 'aaaId=' + id + '&companyName=' + '&type=' + this.type;

                let res = await getCompanyDetailMenuList('code=' + id + '&type=' + 7);
                let power = res.data[6].power;
                if (power) {
                    this.getrelatedinport(params, index, params1);
                } else {
                    const okButton = document.getElementById('ok');
                    this.newDialog = true;
                    this.params = params;
                    this.params1 = params1;
                    this.Obj.name = nameEn;
                    this.Obj.id = id;
                    this.Obj.index = index;
                }
            } else {
                this.parentTablelist[index];
                if (this.parentTablelist[index].chartInstance) {
                    this.parentTablelist[index].chartInstance.dispose();
                    this.parentTablelist[index].chartInstance = null;
                }
            }
        },
        async getOrdersAdd(type, aid, nameEn) {
            const id3a = aid;

            //1  :线上报告
            let params = {
                companyName: nameEn, //必须
                aaaId: id3a, //必须
                // companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';

            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    //  getCompanyDetailMenuList('code=' + aid + '&type=' + 7).then(res=>{
                    //  });
                    status = true;
                    if (data.msg != 'Saved successfully') {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    }
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that = this;
                    let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

                    let routeData = '';
                    setTimeout(function () {
                        if (userinfo.type == '3') {
                            routeData = that.$router.resolve({
                                path: '/account/quota',
                            });
                        } else {
                            routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });
                        }

                        window.open(routeData.href, '_blank');
                    }, 2000);

                    status = false;
                }
            });
            this.getliulantime(params, 'Export');
            return status;
        },

        getrelatedinport(params, index, params1) {
            getImportOrExport(params1).then(res => {
                let data = JSON.parse(crypto.decrypt(res));
                if (data.code === true) {
                    this.parentTablelist[index].loading2 = false;
                    let obj = data.data;

                    // this.transactionsTable = obj.transactions
                    this.parentTablelist[index].buyersTable = obj.buyers;
                    this.parentTablelist[index].countriesTable = obj.countries;

                    const list = obj.trends;
                    if (list) {
                        list.forEach((item, inde) => {
                            this.parentTablelist[index].trendYList.push(item.translation);
                            this.parentTablelist[index].trendXList.push(item.year);
                        });
                    }
                    let that = this;
                    that.loginStatus1[index] = true;

                    const option = {
                        tooltip: {
                            borderWidth: 1,
                            borderColor: 'rgba(0, 0, 0, 0.2)',
                            textStyle: {
                                color: '#022955',
                                fontSize: 14,
                            },
                            backgroundColor: '#ffffff',
                            shadowColor: 'rgba(0, 0, 0, 0.2)',
                            shadowOffsetY: 2,
                            shadowBlur: 4,
                            formatter: function (params) {
                                params['value'] = that.filter(params['value']);
                                return params['seriesName'] + '<br/>' + params['name'] + '<br/>' + params['value'];
                            },
                        },
                        grid: {
                            left: '6%',
                            right: '60',
                            bottom: '60',
                        },
                        xAxis: {
                            data: that.parentTablelist[index].trendXList,
                            axisLabel: {
                                textStyle: {
                                    color: '#8497AB',
                                    fontSize: 10,
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#8497AB',
                                },
                            },
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                textStyle: {
                                    color: '#8497AB',
                                    fontSize: 10,
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#8497AB',
                                },
                            },
                            axisTick: {
                                show: true,
                                length: 4,
                            },
                            splitLine: {
                                show: false,
                            },
                        },
                        series: [
                            {
                                name: 'No. of Buyer',
                                type: 'bar',
                                barWidth: '20',
                                itemStyle: { color: '#8497AB' },
                                emphasis: {
                                    itemStyle: {
                                        color: '#FF7600',
                                    },
                                },
                                data: that.parentTablelist[index].trendYList,
                            },
                        ],
                    };
                    // this.parentTablelist[index].cardlist2 = this.list;

                    const chartDom = this.$refs.chartRef[index];
                    const chart = this.$echarts.getInstanceByDom(chartDom) || this.$echarts.init(chartDom);
                    chart.setOption(option);
                    this.parentTablelist[index].chartInstance = chart;
                    this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                    // resolve(data.data)
                } else {
                    this.parentTablelist[index].loading2 = false;
                    this.parentTablelist[index].buyersTable = [];
                    this.parentTablelist[index].countriesTable = [];

                    // Message({
                    //   message: '账户或密码错误！',
                    //   type: 'warning'
                    // })
                    // return Promise.reject('error')
                }
            });

            // let params1 = 'aaaId=' + id3a +  '&type=' + this.type + '&companyName=' ;
            // let params = 'aaaId=156107587326&countryCode=CN&type=1'
            let that = this;
            setTimeout(function () {
                getImportExportTransaction(params).then(result => {
                    let data = JSON.parse(crypto.decrypt(result));
                    console.log('result- getImport', data);
                    if (data && data.code && data.data) {
                        that.parentTablelist[index].loading2 = false;
                        that.parentTablelist[index].transactionsTable = data.data.rows;
                        that.parentTablelist[index].page.total = Number(data.data.total);
                        that.$set(that.parentTablelist, index, that.parentTablelist[index]);
                    } else {
                        that.parentTablelist[index].loading2 = false;
                        that.parentTablelist[index].transactionsTable = [];

                        // this.parentTablelist[index].page.total=0
                    }
                });
            }, 300);
        },
        // 点击跳转
        setid1(item) {
            // sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + item.aaaid + '&companyCountry=' + '',
            });

            window.open(routeData.href, '_blank');
        },
    },
};
</script>
<style scoped lang="less">
.perform_box {
    margin-top: 60px;
}
.ehcarswide {
    width: 1150px;
    height: 568px;
}
.title {
    margin-bottom: 30px;
}
.m_b {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}

/* tab */
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
    padding: 11px 75px;
}
.result_tab {
    padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 4px 75px;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 150px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}
.market_box {
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden;
}
.tab_box /deep/ .el-table__body,
.tab_box .el-table__footer,
.tab_box /deep/ .el-table__header {
    width: auto !important;
}

.ellipsis {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
}
.title {
    display: flex;
    align-items: center;

    justify-content: space-between;
    // margin-top: 69px;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.table {
    margin-top: 20px;
}
.footer {
    margin-top: 20px;
    padding: 20px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
        display: flex;
    }
}
.infinite-list-wrapper {
    padding: 4px;
}
.relatedport {
    margin: 10px 0;
    /* float: right; */
    display: flex;
    justify-content: flex-end;
}
.back-to-top {
    z-index: 999;
    position: fixed;
    transition: opacity 0.4s ease-in-out 0s;
    opacity: 1;
    box-sizing: border-box;
    bottom: 86px;
    right: 45px;
    cursor: pointer;
    /* background-color: #1290c9; */
}

.gaodu {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #1290c9;
    color: #fff;
    font-size: 20px;
}
.line_1 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.detail_cont {
    padding: 0px 10px 10px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
@media (max-width: 821px) {
    .phone_table_box_item {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        padding: 20px 0;
        border-bottom: 1px solid #e0dce5;
    }
    .table_box .phone_table_box_item img {
        width: 28px;
        height: 17px;
        flex: none;
        margin-right: 4px;
        box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right {
        font-size: 14px;
        margin-left: 20px;
        width: 85%;
    }
    .phone_table_box_item_right h1 {
        font-size: 14px;
        font-weight: normal;
    }
    .phone_table_box_item_right h2 {
        font-size: 12px;
        color: #656373;
        font-weight: normal;
        line-height: 22px;
    }
    .tab_box /deep/ .el-tabs__header {
        padding: 11px 5px !important;
    }
    .tab_box /deep/ .el-tabs__nav {
        padding: 4px 0px !important;
    }
    .result_tab {
        padding: 10px !important;
    }
    .footer {
        padding: 10px !important;
    }
    .perform_box {
        margin-top: 20px !important;
    }
    .tab_box /deep/ .el-tabs__item {
        padding: 0 5px !important;
        font-size: 12px !important;
    }
    .ehcarswide {
        width: 380px !important;
    }
    .btn_export {
        width: 100px !important;
        height: 35px !important;
    }
            .phone_table_box_item_right .line_1 {
    white-space: nowrap !important;
}
}
</style>
